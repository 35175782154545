import { SkeletonInput } from '@components/skeleton';
import {
  useListAssetAdresses,
  useListAssets,
  useListFilteredMeters,
} from 'api/assets/assetsServices';
import { UTILITY_TYPE } from 'lib/constants';
import { type FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiComboBoxInput } from '../ComboBox';
import { MultiSelectInput } from '../SelectInput/MultiSelectInput';
import { type DropdownOptions } from '../SelectInput/MultiSelectInput/MultiSelectInput';
import { type FieldError } from 'react-hook-form';

export interface SearchMeterInputProps {
  value?: DropdownOptions[];
  onChange?: (value: any) => void;
  initialValues?: DropdownOptions[];
  isFetching?: boolean;
  error?: FieldError | undefined;
}

const SearchMeterInput: FC<SearchMeterInputProps> = ({
  value,
  initialValues,
  isFetching,
  onChange,
  error,
}) => {
  const [assetsQuery, setAssetsQuery] = useState('');
  const [locationsQuery, setLocationsQuery] = useState('');
  const [metersQuery, setMetersQuery] = useState('');
  const [assets, setAssets] = useState<number[] | []>([]);
  const [addresses, setAddresses] = useState<number[] | []>([]);
  const [utilityTypes, setUtilityTypes] = useState<string[] | []>([]);
  const { t } = useTranslation();

  const { data: assetsList, isFetching: isFetchingAssets } = useListAssets({
    query: assetsQuery,
  });
  const { data: locationList, isFetching: isFetchingLocations } =
    useListAssetAdresses({
      query: locationsQuery,
    });
  const { data: metersList, isFetching: isFetchingMeters } =
    useListFilteredMeters({
      assets,
      addresses,
      utilityTypes,
      query: metersQuery,
    });

  const assetsOptions = useMemo(() => {
    return (
      assetsList?.pages?.flatMap(
        (page) =>
          page?.assets?.map((asset) => ({ name: asset.name, value: asset.id }))
      ) ?? []
    );
  }, [assetsList]);

  const locationsOptions = useMemo(() => {
    return (
      locationList?.addresses?.map((location) => ({
        name: ` ${location.street} ${location.number}, ${location.city} ${
          location?.province ?? ''
        }`,
        value: location.id,
      })) ?? []
    );
  }, [locationList]);

  const metersOptions = useMemo(() => {
    return (
      metersList?.meters?.map((meter) => ({
        name: meter.name,
        value: meter.id,
      })) ?? []
    );
  }, [metersList]);

  const handleAssetsOnChange = (options: DropdownOptions[]): void => {
    const optionsValue = options?.map((option) => option.value as number);
    setAssets(optionsValue ?? []);
  };

  const handleAddressesOnChange = (options: DropdownOptions[]): void => {
    const optionsValue = options?.map((option) => option.value as number);
    setAddresses(optionsValue ?? []);
  };

  const handleUtilityTypeOnChange = (options: string[]): void => {
    setUtilityTypes(options);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between gap-4 relative">
        {isFetching ? (
          <SkeletonInput containerClassName="flex-1" />
        ) : (
          <MultiComboBoxInput
            onChange={handleAddressesOnChange}
            placeholder={t('common.locations')}
            options={locationsOptions}
            query={locationsQuery}
            isFetching={isFetchingLocations}
            handleQuery={setLocationsQuery}
            removeRelativeContainer={true}
            disableSelectedList={true}
          />
        )}

        {isFetching ? (
          <SkeletonInput containerClassName="flex-1" />
        ) : (
          <MultiComboBoxInput
            onChange={handleAssetsOnChange}
            placeholder={t('common.assets')}
            options={assetsOptions}
            query={assetsQuery}
            isFetching={isFetchingAssets}
            handleQuery={setAssetsQuery}
            removeRelativeContainer={true}
            disableSelectedList={true}
          />
        )}
        {isFetching ? (
          <SkeletonInput containerClassName="flex-1" />
        ) : (
          <MultiSelectInput
            multiple={true}
            placeholder={t('common.utilityTypes')}
            options={UTILITY_TYPE}
            value={utilityTypes}
            onChange={handleUtilityTypeOnChange}
            disableSelectedList={true}
          />
        )}
      </div>
      <div>
        {isFetching ? (
          <SkeletonInput />
        ) : (
          <MultiComboBoxInput
            selectAll={true}
            error={error}
            value={value}
            placeholder={t('common.placeholders.selectMeters')}
            options={metersOptions}
            query={metersQuery}
            onChange={onChange}
            isFetching={isFetchingMeters}
            handleQuery={setMetersQuery}
            initialValues={initialValues}
          />
        )}
      </div>
    </div>
  );
};
export default SearchMeterInput;
