import { Accordion } from '@components/accordion';
import { Button } from 'layout/Button';
import { type FC, type HTMLAttributes, useEffect, useState } from 'react';
import {
  EnervisioItemsAlignment,
  EnervisionRootCauseItemContainer,
} from './styled';
import NewsImg from '@assets/images/news.svg';
import { IoMdThumbsDown, IoMdThumbsUp } from 'react-icons/io';
import { FiThumbsUp } from 'react-icons/fi';
import { LuThumbsDown } from 'react-icons/lu';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import {
  useFetchEnervisionRootCauses,
  useSelectEnervisionRootCause,
  useUpdateEnervisionFeedback,
} from 'api/changes/enervisionService';
import { type EnervisionRootCause } from 'interfaces/change/Enervision.interface';

const BUSINESS_CHANGE_ID = 9;

enum FeedbackEnum {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

interface EnervisionComponentProps {
  changeId?: string;
  handleAssign: (value?: number) => void;
}

const EnervisionComponent: FC<EnervisionComponentProps> = ({
  changeId,
  handleAssign,
}) => {
  const { theme } = useTheme();
  const [activeRootCause, setActiveRootCause] = useState<
    EnervisionRootCause | undefined
  >();
  const [feedback, setFeedback] = useState({
    positive: false,
    negative: false,
  });
  const { data } = useFetchEnervisionRootCauses(changeId);
  const { mutate: updateEnervisionFeedback } = useUpdateEnervisionFeedback();
  const { mutate: selectEnervisionRootCause } = useSelectEnervisionRootCause();
  const [isFirstRender, setIsFirstRender] = useState(true);

  const handleFeedback = (type: FeedbackEnum): void => {
    const updatedFeedback = { ...feedback };

    if (type === FeedbackEnum.POSITIVE) {
      updatedFeedback.negative = false;
    } else if (type === FeedbackEnum.NEGATIVE) {
      updatedFeedback.positive = false;
    }

    updatedFeedback[type] = !updatedFeedback[type];

    setFeedback(updatedFeedback);

    const enervisionId = activeRootCause?.enervision_id;

    if (changeId && enervisionId) {
      const body = {
        enervision_id: activeRootCause?.enervision_id,
        change_id: changeId,
        positive_feedback: updatedFeedback.positive,
        negative_feedback: updatedFeedback.negative,
      };
      setFeedback(updatedFeedback);
      updateEnervisionFeedback(body);
    }
  };

  useEffect(() => {
    const rootCauses = data?.possible_root_causes;
    if (rootCauses && rootCauses?.length > 0) {
      const selectedRootCause = rootCauses.find(
        (root) => root.is_root_cause_selected
      );
      if (isFirstRender) {
        setActiveRootCause(selectedRootCause ?? rootCauses[0]);
        setIsFirstRender(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (activeRootCause) {
      setFeedback({
        positive: activeRootCause.positive_feedback,
        negative: activeRootCause.negative_feedback,
      });
    }
  }, [activeRootCause]);

  const handleOnClickAssign = ({
    rootCauseId,
    enervisionId,
  }: {
    rootCauseId: number;
    enervisionId: number;
  }): void => {
    if (changeId && enervisionId) {
      selectEnervisionRootCause({ changeId, enervisionId });
      handleAssign(rootCauseId);
    }
  };

  return (
    <div>
      {data?.possible_root_causes && data.possible_root_causes.length > 0 && (
        <Accordion
          title="EnerVision Insight -  Possible Root Cause"
          defaultOpen={true}
          variant="gray"
          addorment={{
            startAddorment: <img src={NewsImg} height={16} width={16} />,
          }}
        >
          <div className="flex flex-col gap-4">
            <EnervisioItemsAlignment
              $length={data?.possible_root_causes?.length ?? 0}
            >
              {data?.possible_root_causes?.map((option) => (
                <EnervisionItem
                  key={option.id}
                  title={option.name}
                  percentage={option.likelihood_percentage}
                  active={
                    activeRootCause?.enervision_id === option.enervision_id
                  }
                  onClick={() => {
                    setActiveRootCause(option);
                  }}
                />
              ))}
            </EnervisioItemsAlignment>
            <div>{activeRootCause?.description}</div>
            <div className="flex flex-col gap-4">
              <h5 className="font-bold">How to validate</h5>
              <p>{activeRootCause?.instructions}</p>
            </div>
            {activeRootCause?.root_cause_id !== BUSINESS_CHANGE_ID && (
              <div className="flex flex-col gap-4">
                <h5 className="font-bold">How to solve</h5>
                <p>{activeRootCause?.solution}</p>
              </div>
            )}

            {activeRootCause?.source && (
              <div>
                <p className="italic">{`Source: ${activeRootCause?.source}`}</p>
              </div>
            )}
            <div className="flex justify-between items-end">
              <div className="flex flex-col">
                <h5 className="font-bold">Was this helpful?</h5>
                <div className="flex gap-4 pt-3">
                  <div
                    className={`p-2 cursor-pointer rounded-full hover:bg-primary-lighter-2 ${
                      feedback.negative ? 'bg-primary-lighter-2' : ''
                    }`}
                    onClick={() => {
                      handleFeedback(FeedbackEnum.NEGATIVE);
                    }}
                  >
                    {feedback.negative ? (
                      <IoMdThumbsDown size={24} color={theme.colors.primary} />
                    ) : (
                      <LuThumbsDown size={24} color={theme.colors.primary} />
                    )}
                  </div>
                  <div
                    className={`p-2 cursor-pointer rounded-full hover:bg-primary-lighter-2 ${
                      feedback.positive ? 'bg-primary-lighter-2' : ''
                    }`}
                    onClick={() => {
                      handleFeedback(FeedbackEnum.POSITIVE);
                    }}
                  >
                    {feedback.positive ? (
                      <IoMdThumbsUp size={24} color={theme.colors.primary} />
                    ) : (
                      <FiThumbsUp size={24} color={theme.colors.primary} />
                    )}
                  </div>
                </div>
              </div>
              <Button
                label="Assign as root cause"
                onClick={() => {
                  if (activeRootCause?.root_cause_id) {
                    handleOnClickAssign({
                      rootCauseId: activeRootCause.root_cause_id,
                      enervisionId: activeRootCause.enervision_id,
                    });
                  }
                }}
              />
            </div>
          </div>
        </Accordion>
      )}
    </div>
  );
};

interface PossibleRootCauseItemProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  percentage: number;
  active: boolean;
}

const EnervisionItem: FC<PossibleRootCauseItemProps> = ({
  title,
  percentage,
  active,
  ...props
}) => {
  return (
    <EnervisionRootCauseItemContainer
      $active={active}
      data-enervision-item
      {...props}
    >
      <p className="text-base font-bold">{percentage}%</p>
      <p className="text-sm">{title}</p>
    </EnervisionRootCauseItemContainer>
  );
};
export default EnervisionComponent;
