import { Accordion } from '@components/accordion';
import { AccordionSection } from '@components/accordionSection';
import { Modal } from '@components/modal';
import { useFetchAsset } from 'api/assets/assetsServices';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import { type Meter } from 'interfaces/assets/Meters.interface';
import { DetailsRow } from 'pages/Assets/Meters/modal/MeterDetailsModal/styled';
import { type FC, type ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
interface AssetDetailsModalProps extends Disclosure {
  assetId?: number;
  handleEditOpen: () => void;
}

const AssetDetailsModal: FC<AssetDetailsModalProps> = ({
  assetId,
  isOpen,
  handleOpen,
  handleClose,
  handleEditOpen,
}) => {
  const { t } = useTranslation();
  const [foundMeter, setFoundMeter] = useState(false);
  const { data, isFetching } = useFetchAsset(assetId);

  const createMeterAccordion = (meter: Meter): ReactNode => {
    if (!foundMeter && meter) setFoundMeter(true);
    return <Accordion key={meter.id} title={meter.name} enableTooltip={true} />;
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      title={data?.asset.name}
      useSkeleton={true}
      maxWidth={600}
      actionBtns={{
        secondary: {
          label: 'Edit',
          onClick: () => {
            handleClose();
            handleEditOpen();
          },
        },
      }}
    >
      <div className="flex flex-col gap-6">
        <div className="border border-gray-10 rounded-lg">
          <div className="px-4 py-2 border-b border-gray-10">
            {isFetching ? (
              <Skeleton width={100} />
            ) : (
              <h3 className="uppercase text-gray-60 font-bold">
                {t('common.details')}
              </h3>
            )}
          </div>
          <DetailsRow>
            {isFetching ? (
              <Skeleton width={120} />
            ) : (
              <p className="text-gray-60">{t('common.assetType')}</p>
            )}
            <div>
              {isFetching ? (
                <Skeleton width={120} height={24} borderRadius={20} />
              ) : (
                <p>{data?.asset.type}</p>
              )}
            </div>
          </DetailsRow>
          <DetailsRow>
            {isFetching ? (
              <Skeleton width={120} />
            ) : (
              <p className="text-gray-60">{t('common.floorArea')}</p>
            )}
            <div>
              {isFetching ? (
                <Skeleton width={120} height={24} borderRadius={20} />
              ) : (
                <div>{data?.asset.surface_area}</div>
              )}
            </div>
          </DetailsRow>
          <DetailsRow>
            {isFetching ? (
              <Skeleton width={120} />
            ) : (
              <p className="text-gray-60">{t('common.function')}</p>
            )}
            <div>
              {isFetching ? (
                <Skeleton width={120} height={24} borderRadius={20} />
              ) : (
                <div>{data?.asset.function_string ?? '-'}</div>
              )}
            </div>
          </DetailsRow>
          <DetailsRow>
            {isFetching ? (
              <Skeleton width={120} />
            ) : (
              <p className="text-gray-60">{t('common.location')}</p>
            )}
            <div>
              {isFetching ? (
                <Skeleton width={120} height={24} borderRadius={20} />
              ) : (
                <div>
                  {data?.asset.address.street} {data?.asset.address.number},{' '}
                  {data?.asset.address.zip_code} - {data?.asset.address.city}/
                  {data?.asset.address.country_iso}
                </div>
              )}
            </div>
          </DetailsRow>
        </div>
        <AccordionSection title={t('common.meters')} isLoading={isFetching}>
          {data?.asset?.meters?.map((meter) => {
            return createMeterAccordion(meter);
          })}
          {!foundMeter && (
            <Accordion
              title={t('common.empty.accordion', {
                value: t('common.meters').toLowerCase(),
              })}
            />
          )}
        </AccordionSection>
      </div>
    </Modal>
  );
};

export default AssetDetailsModal;
