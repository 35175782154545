import { Accordion } from '@components/accordion';
import { FLabel } from '@components/form/FLabel';
import { FMultiComboBox } from '@components/form/FMultiComboBox';
import { FTextField } from '@components/form/FTextField';
import { type DropdownOptions } from '@components/inputs/SelectInput/MultiSelectInput/MultiSelectInput';
import {
  useFetchCompanies,
  useListPermissionMap,
} from 'api/sysadmin/sysAdminServices';
import { type FeatureFlagFromProvider } from 'context/FeatureFlagProvider/FeatureFlagProvider';
import { useDebounce } from 'hooks/useDebounce';
import { FEATURE_FLAGS_LIST } from 'lib/featureFlagsList';
import { type FC, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Markdown from 'react-markdown';
import { stringToCapitalize } from 'utils/formatters/string/stringFormatter';
import {
  type FeatureFlagSchemaType,
  ManageFeatureFlagControlNames,
} from './schema';

interface ManageFeatureFlagFormProps {
  initialValues?: FeatureFlagSchemaType;
}

const ManageFeatureFlagForm: FC<ManageFeatureFlagFormProps> = ({
  initialValues,
}) => {
  const [routesQuery, setRoutesQuery] = useState('');
  const [companyQuery, setCompanyQuery] = useState('');
  const { control } = useFormContext();

  const debouncedRoutesQuery = useDebounce(routesQuery, 300);
  const debouncedCompaniesQuery = useDebounce(companyQuery, 300);

  const { data: permissionList, isFetching: isFetchingPermission } =
    useListPermissionMap({
      query: debouncedRoutesQuery,
      perPage: 100,
    });

  const { data: companyList, isFetching: isFetchingCompanies } =
    useFetchCompanies({ query: debouncedCompaniesQuery, perPage: 100 });

  const companyOptions = useMemo(() => {
    if (companyList) {
      return companyList?.companies?.map((company) => ({
        name: company.name,
        value: company.id,
      }));
    }
    return [];
  }, [companyList]);

  const routeOptions = useMemo(() => {
    if (permissionList) {
      return permissionList?.pages?.flatMap(
        (page) =>
          page?.permission_maps?.map((permission) => ({
            name: `${permission.method} - ${permission.endpoint}`,
            value: permission.id,
          }))
      );
    }
    return [];
  }, [permissionList]);

  function createDescriptionString(): string {
    let result = '';

    for (const key in FEATURE_FLAGS_LIST) {
      if (Object.prototype.hasOwnProperty.call(FEATURE_FLAGS_LIST, key)) {
        result += `\n**${stringToCapitalize(key)}**:\n`;

        const section =
          FEATURE_FLAGS_LIST[key as keyof typeof FEATURE_FLAGS_LIST];

        for (const sectionKey in section) {
          result += `- ${stringToCapitalize(sectionKey)}:`;

          const endpoint = section[
            sectionKey as keyof typeof section
          ] as FeatureFlagFromProvider;

          result += `  ${endpoint.method} - ${endpoint.endpoint}\n`;
          result += endpoint?.description ? `  ${endpoint.description}\n` : ``;
        }
      }
    }

    return result;
  }

  return (
    <form className="flex flex-col gap-6">
      <FTextField
        label="Name"
        name={ManageFeatureFlagControlNames.name}
        placeholder={'Enter Flag name'}
        id="flag-name"
      />
      <FMultiComboBox
        selectAll={true}
        label="Routes"
        placeholder="Select Routes"
        name={ManageFeatureFlagControlNames.routes}
        query={routesQuery}
        handleQuery={setRoutesQuery}
        options={routeOptions ?? []}
        isFetching={isFetchingPermission}
        initialValues={initialValues?.routes as DropdownOptions[]}
        selectedOptionsContainerClasses={'break-all	'}
        id="routes"
      />

      <Accordion title={'Endpoints map to components/page'}>
        <div>
          <Markdown className={'whitespace-pre-wrap'}>
            {createDescriptionString()}
          </Markdown>
        </div>
      </Accordion>

      <FMultiComboBox
        selectAll={true}
        label="Company"
        placeholder="Select companies"
        name={ManageFeatureFlagControlNames.company}
        query={companyQuery}
        handleQuery={setCompanyQuery}
        options={companyOptions}
        isFetching={isFetchingCompanies}
        initialValues={initialValues?.company as DropdownOptions[]}
        selectedOptionsContainerClasses={'break-all	'}
        id="companies"
      />
      <div className="flex gap-3 items-center">
        <FLabel id="active">Active</FLabel>
        <Controller
          name={ManageFeatureFlagControlNames.active}
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <input
                id="active"
                className="w-[15px]"
                type={'checkbox'}
                checked={field.value}
                {...field}
              />
            );
          }}
        />
      </div>
    </form>
  );
};
export default ManageFeatureFlagForm;
