import { SubOptionsSelectInput } from '@components/inputs/SelectInput/SubOptionsSelectInput';
import {
  useFetchRootCauses,
  useUpdateChange,
} from 'api/changes/changesServices';
import { useFeatureFlag } from 'context/FeatureFlagProvider/FeatureFlagProvider';
import { t } from 'i18next';
import { CardContainer, CardTitle } from 'layout/Card';
import { FEATURE_FLAGS_LIST } from 'lib/featureFlagsList';
import { type FC, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Mixpanel } from 'utils/mixpanel/mixpanel_agent';
import EnervisionComponent from './EnervisionComponent';

interface RootCauseProps {
  rootCauseId?: number;
  changeId: string | undefined;
  isLoading?: boolean;
}

const RootCause: FC<RootCauseProps> = ({
  rootCauseId,
  changeId,
  isLoading,
}) => {
  const { mutate, isSuccess } = useUpdateChange(changeId);
  const { data } = useFetchRootCauses();
  const { hasFeatureFlagPermission } = useFeatureFlag();
  const [selectedRootCause, setSelectedRootCause] = useState<
    number | undefined
  >(rootCauseId);

  const submit = (id: string | number | undefined): void => {
    if (id) {
      const body = {
        root_cause_id: id,
      };
      mutate(body);
    }
  };

  useEffect(() => {
    if (rootCauseId) setSelectedRootCause(rootCauseId);
  }, [rootCauseId]);

  useEffect(() => {
    if (isSuccess) {
      Mixpanel.track('root_cause_updated', {
        root_cause: selectedRootCause,
        change_id: changeId,
      });
    }
  }, [isSuccess]);

  return (
    <CardContainer>
      <CardTitle
        title={t('anomaliesDetailsPage.rootCause.title')}
        subtitle={t('anomaliesDetailsPage.rootCause.subTitle')}
        style={{ fontSize: 14 }}
        isLoading={isLoading}
        skeletonVariant="subtitle"
      />

      <div className="flex flex-col gap-4 mt-4">
        {hasFeatureFlagPermission(
          FEATURE_FLAGS_LIST.changes.possible_root_causes
        ) ? (
          isLoading ? (
            <Skeleton height={415} />
          ) : (
            <EnervisionComponent
              changeId={changeId}
              handleAssign={(value) => {
                setSelectedRootCause(value);
                submit(value);
              }}
            />
          )
        ) : null}
        <div>
          {isLoading ? (
            <Skeleton width={80} />
          ) : (
            <p className="font-bold mb-2">Root cause</p>
          )}
          {isLoading ? (
            <Skeleton borderRadius={'8px'} height={'40px'} />
          ) : (
            <SubOptionsSelectInput
              options={data ?? []}
              placeholder={t('anomaliesDetailsPage.select.selectLabel')}
              value={selectedRootCause}
              onChange={(value) => {
                setSelectedRootCause(value as number);
                submit(value);
              }}
              disabled={
                !hasFeatureFlagPermission(FEATURE_FLAGS_LIST.changes.patch)
              }
            />
          )}
        </div>
      </div>
    </CardContainer>
  );
};
export default RootCause;
