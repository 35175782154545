import { Accordion } from '@components/accordion';
import { AccordionSection } from '@components/accordionSection';
import { Modal } from '@components/modal';
import { useFetchGroup } from 'api/assets/assetsServices';
import { RoleEnum } from 'enums/RoleEnum';
import { useAuth } from 'hooks/contexts/AuthContext';
import { type Asset } from 'interfaces/assets/Asset.interface';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import { type Meter } from 'interfaces/assets/Meters.interface';
import { type FC, type ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

interface GroupDetailsModalProps extends Disclosure {
  groupId?: number;
  handleEditOpen?: () => void;
}

const GroupDetailsModal: FC<GroupDetailsModalProps> = ({
  isOpen,
  groupId,
  handleClose,
  handleOpen,
  handleEditOpen,
}) => {
  const { t } = useTranslation();
  const [foundGroup, setFoundGroup] = useState(false);
  const [foundBuilding, setFoundBuilding] = useState(false);
  const { user } = useAuth();

  const { data, isFetching } = useFetchGroup(groupId);

  const createBuildingAccordion = (asset: Asset): ReactNode => {
    if (!foundBuilding && asset) setFoundBuilding(true);
    return <Accordion enableTooltip={true} key={asset.id} title={asset.name} />;
  };

  const createMeterAccordion = (meter: Meter): ReactNode => {
    if (!foundGroup) setFoundGroup(true);
    return <Accordion enableTooltip={true} key={meter.id} title={meter.name} />;
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      maxWidth={650}
      title={data?.group.name}
      useSkeleton={true}
      actionBtns={
        user?.role === RoleEnum.ADMINISTRATOR ||
        user?.role === RoleEnum.SYSADMIN
          ? {
              secondary: {
                label: t('common.buttons.edit'),
                onClick: () => {
                  handleClose();
                  handleEditOpen?.();
                },
                isLoading: isFetching,
              },
            }
          : undefined
      }
    >
      <div className="flex flex-col gap-8">
        {isFetching ? (
          <div className="flex justify-between">
            <Skeleton width={120} />
            <Skeleton width={80} />
          </div>
        ) : (
          <div>
            <label className="font-bold text-gray-60">
              {t('common.description')}
            </label>
            <p className="phone:max-w-[200px] laptop:max-w-[400px] line-clamp-3">
              {data?.group.description}
            </p>
          </div>
        )}

        <AccordionSection title={t('common.meter')} isLoading={isFetching}>
          {data?.group?.meters?.map((meter) => createMeterAccordion(meter))}

          {!foundGroup && (
            <Accordion
              enableTooltip={true}
              title={t('common.empty.accordion', {
                value: t('common.groups').toLowerCase(),
              })}
            />
          )}
        </AccordionSection>

        <AccordionSection title={t('common.asset')} isLoading={isFetching}>
          {data?.group?.assets?.map(
            (asset) =>
              asset.type === 'BUILDING' && createBuildingAccordion(asset)
          )}

          {!foundBuilding && (
            <Accordion
              enableTooltip={true}
              title={t('common.empty.accordion', {
                value: t('common.groups').toLowerCase(),
              })}
            />
          )}
        </AccordionSection>
      </div>
    </Modal>
  );
};
export default GroupDetailsModal;
