import styled from 'styled-components';
import tw from 'twin.macro';

interface EnervisionRootCauseItemContainerProps {
  $active?: boolean;
}
interface EnervisioItemsAlignmentProps {
  $length: number;
}

export const EnervisionRootCauseItemContainer = styled.div<EnervisionRootCauseItemContainerProps>`
  ${tw`flex flex-col justify-center items-center px-4 py-3 w-[170px] rounded-lg text-center cursor-pointer min-h-[86px]`}
  ${(props) =>
    props.$active
      ? tw`bg-secondary text-white`
      : tw`bg-primary-lighter-2 text-secondary`}
`;

export const EnervisioItemsAlignment = styled.div<EnervisioItemsAlignmentProps>`
  ${tw`flex justify-start items-stretch gap-4`}
  ${(props) => (props.$length > 2 ? tw`justify-between` : tw`justify-start`)}
`;
