import { Modal } from '@components/modal';
import { useFetchGroup, useUpdateGroup } from 'api/assets/assetsServices';
import useHRForm from 'hooks/useHRForm';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import queryClient from 'lib/queryClient';
import { type FC, useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ManageGroupForm } from '../../form/ManageGroupForm';
import { groupSchema } from '../../form/ManageGroupForm/schema';

interface EditGroupModalProps extends Disclosure {
  groupId?: number;
}

const EditGroupModal: FC<EditGroupModalProps> = ({
  isOpen,
  handleClose,
  groupId,
}) => {
  const { data, isFetching } = useFetchGroup(groupId);

  const { t } = useTranslation();

  const initialValues = useMemo(() => {
    if (!data) return;
    const meters = data?.group.meters.map((meter) => ({
      value: meter.id,
      name: meter.name,
    }));
    return {
      name: data.group?.name,
      description: data.group?.description ?? '',
      meters,
    };
  }, [data]);

  const { methods } = useHRForm({
    schema: groupSchema,
    values: initialValues,
    initialValues,
  });

  const { mutate, isSuccess, isPending } = useUpdateGroup(groupId);

  const handleUpdateGroup = async (): Promise<void> => {
    const body = methods.getValues();
    const parsedMetersId = body.meters
      ?.filter((meter) => meter.value !== 'selectAll')
      .map((meter) => ({ id: meter.value }));

    mutate({ ...body, meters: parsedMetersId });
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      const refetch = async (): Promise<void> => {
        await queryClient.invalidateQueries({
          queryKey: ['listGroups', 'fetchGroupDetails'],
        });
        await queryClient.refetchQueries({
          stale: true,
        });
      };
      void refetch();
    }
  }, [isSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={t('assetsPage.group.editGroup')}
      useSkeleton={true}
      maxWidth={550}
      actionBtns={{
        primary: {
          label: t('common.buttons.saveChanges'),
          onClick: methods.handleSubmit(handleUpdateGroup),
          isLoading: isPending,
        },
        secondary: { label: t('common.buttons.cancel') },
      }}
    >
      <FormProvider {...methods}>
        <ManageGroupForm
          initialValues={initialValues}
          isFetching={isFetching}
        />
      </FormProvider>
    </Modal>
  );
};
export default EditGroupModal;
